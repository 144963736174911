@import "./_variables.scss";

.cdk-overlay-pane {
  max-width: 80%;
  &.hidden-dialog {
    display: none;
  }
  .mdc-dialog__container {
    .mat-mdc-dialog-surface {
      display: flex;
      >* {
        display: flex;
        height: 100%;
        min-height: 0;
      }
    }
    background-color: $white;
    padding: 0;
    height: 100%;
    min-height: 0;
    .dialog-content {
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      width: 100%;
      min-height: 0;
      .dialog-header, .dialog-body, .dialog-footer {
        width: 100%;
        display: flex;
        flex-flow: row;
      }
      .dialog-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px $text-secondary;
        padding: 16px 21px;
        flex-shrink: 0;
        .title, .title-container {
          flex-grow: 1;
          color: $text-base;
          font-size: 24px;
          line-height: 28px;
          display: flex;
          align-items: center;
          .icon {
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            margin-right: 11px;
            &.warning {
              background: url('/assets/icons/icon-dialog-warning.svg') no-repeat center;
            }
            &.error {
              background: url('/assets/icons/icon-dialog-error.svg') no-repeat center;
            }
            &.success {
              background: url('/assets/icons/icon-dialog-success.svg') no-repeat center;
            }
          }
        }
        .close-icon {
          flex-shrink: 0;
          background: url("/assets/icons/icon-close.svg") no-repeat center;
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .dialog-body {
        flex-flow: column;
        flex-grow: 1;
        align-items: flex-start;
        color: $text-base;
        padding: 24px;
        min-height: 0;
        height: 100%;
        overflow: hidden;
        word-break: break-word;
      }
      .dialog-footer {
        padding: 16px;
        align-items: center;
        flex-shrink: 0;
        border-top: solid 1px $text-secondary;
        justify-content: space-between;
        &.align-right {
          justify-content: flex-end;
        }
      }
    }
  }
  .mat-mdc-snack-bar-container {
    padding: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    background-color: $white;
    border-radius: 4px;
    .mdc-snackbar__surface {
      padding-right: 0;
      .mat-mdc-snack-bar-label {
        padding: 0;
      }
    }
  }
}

.popover {
  z-index: 1000;
  max-width: 350px;
  &.modification-models-popover {
    max-width: 362px;
    .models-field {
      max-height: 245px;
      overflow: hidden;
      display: flex;
      flex-flow: column;
      min-height: 0;
      height: 100%;
    }
  }
  &.bs-popover-bottom {
    .popover-arrow {
      top: -24px;
      border-color: transparent;
      border-bottom-color: $tip-color-ok;
      border-width: 12px 8px;
      border-style: solid;
      width: 16px;
      height: 24px;
    }
  }
  &.bs-popover-top {
    .popover-arrow {
      bottom: -24px;
      border-color: transparent;
      border-top-color: $tip-color-ok;
      border-width: 12px 8px;
      border-style: solid;
      width: 16px;
      height: 24px;
    }
  }
  .popover-body {
    background-color: $tip-color-ok;
    padding: 16px;
    border-radius: 8px;
  }
  &.warning-tip {
    &.bs-popover-bottom .popover-arrow {
      border-bottom-color: $tip-color-warning;
    }
    &.bs-popover-top .popover-arrow {
      border-top-color: $tip-color-warning;
    }
    .popover-body {
      background-color: $tip-color-warning;
    }
  }
  &.error-tip {
    &.bs-popover-bottom .popover-arrow {
      border-bottom-color: $error-highlight;
    }
    &.bs-popover-top .popover-arrow {
      border-top-color: $error-highlight;
    }
    .popover-body {
      background-color: $error-highlight;
    }
  }
  &.shift-left .popover-body {
    position: relative;
    left: 40px;
  }
}
.loading-dialog {
  .spinner-three-bounce {
    display: none;
  }
  .loading-banner {
    position: fixed;
    width: 260px;
    height: 170px;
    top: calc(50% - 85px);
    left: calc(50% - 130px);
    z-index: 2000;
    padding: 32px;
    background-color: $white;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    .text {
      margin-top: 24px;
      text-align: center;
      color: $text-base;
      line-height: 24px;
    }
  }
}
