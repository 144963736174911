@import "./_variables.scss";

input[type=text], textarea {
  background: $white;
  border: 1px solid $text-secondary;
  border-radius: 4px;
  color: $text-base;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  outline: none;
  margin: 0;
  &::placeholder {
    color: $text-secondary;
    text-transform: none;
  }
  &.invalid {
    border: solid 1px $error-highlight;
  }
  &:focus {
    border: solid 1px $border-highlight;
  }
  &[disabled], &.disabled {
    background-color: $component-disabled;
    color: $text-disabled;
  }
  &.clearable {
    padding-right: 40px;
  }
}

input[type=text], textarea {
  padding: 11px 12px;
  &.narrow-input {
    padding: 7px 8px;
    &.clearable {
      padding-right: 28px;
    }
  }
}

textarea {
  width: 100%;
  height: 64px;
  padding: 8px;
}

.dropdown-panel {
  width: 100%;
  &.limited-height {
    max-height: 250px;
  }
  display: block;
  border: 1px solid $border-light2;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background-color: $white;
  .dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
    color: $text-base;
    cursor: pointer;
    border-top: 1px solid $border-light2;
    font-size: 14px;
    line-height: 16px;
    .item-text {
      flex-grow: 1;
      word-break: break-word;
    }
    .additional {
      color: $text-disabled;
    }
    &:first-child {
      border-top: none;
    }
    &.selected {
      background-color: $selected-component;
      cursor: default;
    }
    &:hover {
      background-color: $active-highlight;
    }
    &.disabled {
      color: $text-disabled;
      cursor: default;
      &:hover {
        background-color: $white;
      }
    }
  }
  &.items-panel {
    padding: 16px;
    max-height: unset;
    .year-selection-panel {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 16px;
      border-bottom: 1px solid $border-light2;
      margin-bottom: 16px;
    }
    .items-container {
      display: grid;
      grid-gap: 8px 16px;
      gap: 8px 16px;
      .selectable-item {
        color: $table-text;
        padding: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background-color: $active-highlight;
        }
        &.disabled {
          color: $text-secondary;
          cursor: default;
          &:hover {
            background-color: $white;
          }
        }
        &.selected {
          background-color: $button-primary;
          color: $white;
          cursor: default;
          &:hover {
            background-color: $button-primary;
          }
        }

      }
    }
    .years-container {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .months-container {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      .month-item {
        width: 128px;
      }
    }
  }
  .tree-container {
    display: block;
    width: 100%;
    &.monospace {
      font-family: 'Source Code Pro', monospace;
      .label.node-name {
        font-family: 'Source Code Pro', monospace;
      }
    }
    .children-container {
      padding-left: 16px;
      overflow: hidden;
      transition: max-height .8s;
      &.collapsed {
        max-height: 0;
      }
      &.no-padding {
        padding-left: 0;
      }
    }
    .node-row {
      padding: 4px 8px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      cursor: pointer;
      &.hidden {
        display: none;
      }
    }
    .node-icon {
      width: 24px;
      height: 24px;
      &.expand-collapse {
        background: url("/assets/icons/icon-tree-expand-collapse.svg") no-repeat center;
        transition: transform .5s;
        &.collapsed {
          transform: rotate(-90deg);
        }
      }
    }
    .node-name.label {
      font-weight: normal;
    }
  }
}

.checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 8px;
  background: url("/assets/icons/icon-checkbox.svg") no-repeat center $white;
  flex-shrink: 0;
  &:hover {
    background: url("/assets/icons/icon-checkbox-hover.svg") no-repeat center $white;
  }
  &.checked {
    background: url("/assets/icons/icon-checkbox-checked.svg") no-repeat center $white;
    &:hover {
      background: url("/assets/icons/icon-checkbox-checked-hover.svg") no-repeat center $white;
    }
  }
  &.half-checked {
    background: url("/assets/icons/icon-checkbox-halfchecked.svg") no-repeat center $white;
    &:hover {
      background: url("/assets/icons/icon-checkbox-halfchecked-hover.svg") no-repeat center $white;
    }
  }
  &.disabled {
    background: url("/assets/icons/icon-checkbox-disabled.svg") no-repeat center $white;
    cursor: default;
    &:hover {
      background: url("/assets/icons/icon-checkbox-disabled.svg") no-repeat center $white;
    }
    &.checked {
      background: url("/assets/icons/icon-checkbox-checked-disabled.svg") no-repeat center $white;
      &:hover {
        background: url("/assets/icons/icon-checkbox-checked-disabled.svg") no-repeat center $white;
      }
    }
    &.half-checked {
      background: url("/assets/icons/icon-checkbox-halfchecked-disabled.svg") no-repeat center $white;
      &:hover {
        background: url("/assets/icons/icon-checkbox-halfchecked-disabled.svg") no-repeat center $white;
      }
    }
  }
}

.mat-calendar {
  .mat-calendar-content {
    .mat-calendar-table-header {
      th {
        padding-bottom: 12px;
      }
      .mat-calendar-table-header-divider::after {
        left: 8px;
        right: 8px;
        background-color: $border-light2;
      }
    }
  }
}

form {
  .field {
    margin-bottom: 24px;
  }
  .field-name {
    margin-bottom: 8px;
  }
}

.loader {
  width: 32px;
  height: 32px;
  background: url("/assets/icons/loading.svg") no-repeat center;
  animation: spin 1s infinite linear;
  &.centered {
    margin-left: auto;
    margin-right: auto;
  }
}
@keyframes spin {
  from {
    transform: rotate(-359deg);
  }
  to {
    transform: rotate(0deg);
  }
}


