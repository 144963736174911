@import "./_variables.scss";

.inner-view table.results-table thead th {
  padding: 28px 24px;
}
table.results-table {
  width: 100%;
  border-collapse: collapse;
  background: $white;
  word-break: normal;
  tr {
    border-top: 1px solid $border-light4;
    &:first-child {
      border: none; // border-bottom won't work for sticky
    }
    &.totals-row {
      border: none;
      th, td {
        background-color: $component-disabled;
        padding: 12px 24px;
      }
    }
    th, td {
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      padding: 16px 24px;
      &.centered {
        text-align: center;
      }
      &.align-right {
        text-align: right;
      }
      &.thin-space {
        padding: 10px 24px;
      }
      &.button-column {
        line-height: 0;
      }
    }
    th {
      position: sticky;
      top: 0;
      color: $text-base;
      background: $white;
      font-weight: 700;
      text-transform: uppercase;
      box-shadow: inset 0 -1px 0 $border-light; // border-bottom replacement
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        border-top: 1px solid $border-light;
      }
    }
    td {
      font-weight: 400;
      color: $table-text;
      vertical-align: middle;
      &.by-content {
        width: 1%;
        white-space: nowrap;
      }
      &.table-check {
        background-image: url("/assets/icons/icon-table-check-disabled.svg");
        background-repeat: no-repeat;
        background-position: 24px center;
        &.checked {
          background-image: url("/assets/icons/icon-table-check.svg");
        }
        &.centered {
          background-position: center;
        }
      }
      a {
        color: $black;
      }  
    }
  }
  &.static-borders {
    border: solid 1px $border-light2;
    tr {
      border-top: 1px solid $border-light2;
      &:first-child {
        border-top: 1px solid $border-light2;
      }
      th {
        &:before {
          content: none;
        }
        box-shadow: none;
      }
    }
  }
  &.stripes-table {
    border-style: solid;
    border-color: $border-light2;
    border-width: 0 1px 1px 1px;
    tr {
      border-top: none;
      &:nth-child(odd) {
        background-color: $component-disabled;
      }
    }
  }

}

table.grayed-titles {
  border-spacing: 24px 8px;
  margin: -8px -24px;
  th, td {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    text-align: left;
  }
  th {
    color: $text-disabled;
  }
  td {
    color: $text-black;
  }
}
