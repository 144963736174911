@import "@angular/material/prebuilt-themes/indigo-pink.css";
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("~src/assets/fonts/Roboto-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Code Pro";
  src: url("~src/assets/fonts/SourceCodePro-Medium.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}
.view-title, h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-block-start: 0;
  margin-block-end: 0;
  color: #353A3E;
}

.panel-header, h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #353A3E;
  margin: 0;
}

div.panel-header {
  padding: 24px;
}

.text-black {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000;
}

.label {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #2B2B2B;
}

.label-base {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #353A3E;
}

.capitalize {
  text-transform: lowercase;
}
.capitalize:first-letter {
  text-transform: capitalize;
}

.upper-case {
  text-transform: uppercase;
}

.highlighted {
  color: #317DF5;
}

.centered {
  text-align: center;
  vertical-align: center;
}

.inline-block {
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}

hr {
  border: none;
  color: rgba(195, 199, 207, 0.4);
  background-color: rgba(195, 199, 207, 0.4);
  height: 1px;
  margin: 16px 0;
}

.max-attention {
  font-weight: 700;
  color: #FA4242;
  text-transform: uppercase;
}

.comma-separated > *:after {
  content: ", ";
}
.comma-separated > *:last-child:after {
  content: none;
}
.comma-separated.separate-lines > * {
  display: block;
}

p {
  display: inline;
  padding-left: 16px;
}

.top-space {
  margin-top: 24px;
}

.less-space {
  margin-top: 16px;
}

.min-space {
  margin-top: 8px;
}

.info-list {
  padding-left: 16px;
}
.info-list.two-digits {
  padding-left: 22px;
}
.info-list > li {
  padding-top: 20px;
}
.info-list > li:first-child {
  padding-top: 0;
}

.text-grayed {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8E9AA4;
}

.validation {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.015em;
  color: #FA4242;
}
.validation .validation-icon {
  width: 24px;
  height: 24px;
  background: url("/assets/icons/icon-message-error.svg") no-repeat center;
  margin-right: 8px;
}
.validation .message + .message {
  display: none;
}

.red {
  color: #FA4242;
}

.tip-title, .tip-body {
  color: #2B2B2B;
  font-size: 14px;
  line-height: 16px;
}

.tip-title, .tip-info-title {
  font-weight: 700;
}

.p0 {
  padding: 0px;
}

.pt0 {
  padding-top: 0px;
}

.pb0 {
  padding-bottom: 0px;
}

.pl0 {
  padding-left: 0px;
}

.pr0 {
  padding-right: 0px;
}

.p4 {
  padding: 4px;
}

.pt4 {
  padding-top: 4px;
}

.pb4 {
  padding-bottom: 4px;
}

.pl4 {
  padding-left: 4px;
}

.pr4 {
  padding-right: 4px;
}

.p5 {
  padding: 5px;
}

.pt5 {
  padding-top: 5px;
}

.pb5 {
  padding-bottom: 5px;
}

.pl5 {
  padding-left: 5px;
}

.pr5 {
  padding-right: 5px;
}

.p6 {
  padding: 6px;
}

.pt6 {
  padding-top: 6px;
}

.pb6 {
  padding-bottom: 6px;
}

.pl6 {
  padding-left: 6px;
}

.pr6 {
  padding-right: 6px;
}

.p8 {
  padding: 8px;
}

.pt8 {
  padding-top: 8px;
}

.pb8 {
  padding-bottom: 8px;
}

.pl8 {
  padding-left: 8px;
}

.pr8 {
  padding-right: 8px;
}

.p10 {
  padding: 10px;
}

.pt10 {
  padding-top: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.pl10 {
  padding-left: 10px;
}

.pr10 {
  padding-right: 10px;
}

.p12 {
  padding: 12px;
}

.pt12 {
  padding-top: 12px;
}

.pb12 {
  padding-bottom: 12px;
}

.pl12 {
  padding-left: 12px;
}

.pr12 {
  padding-right: 12px;
}

.p14 {
  padding: 14px;
}

.pt14 {
  padding-top: 14px;
}

.pb14 {
  padding-bottom: 14px;
}

.pl14 {
  padding-left: 14px;
}

.pr14 {
  padding-right: 14px;
}

.p15 {
  padding: 15px;
}

.pt15 {
  padding-top: 15px;
}

.pb15 {
  padding-bottom: 15px;
}

.pl15 {
  padding-left: 15px;
}

.pr15 {
  padding-right: 15px;
}

.p16 {
  padding: 16px;
}

.pt16 {
  padding-top: 16px;
}

.pb16 {
  padding-bottom: 16px;
}

.pl16 {
  padding-left: 16px;
}

.pr16 {
  padding-right: 16px;
}

.p20 {
  padding: 20px;
}

.pt20 {
  padding-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.p24 {
  padding: 24px;
}

.pt24 {
  padding-top: 24px;
}

.pb24 {
  padding-bottom: 24px;
}

.pl24 {
  padding-left: 24px;
}

.pr24 {
  padding-right: 24px;
}

.p25 {
  padding: 25px;
}

.pt25 {
  padding-top: 25px;
}

.pb25 {
  padding-bottom: 25px;
}

.pl25 {
  padding-left: 25px;
}

.pr25 {
  padding-right: 25px;
}

.p30 {
  padding: 30px;
}

.pt30 {
  padding-top: 30px;
}

.pb30 {
  padding-bottom: 30px;
}

.pl30 {
  padding-left: 30px;
}

.pr30 {
  padding-right: 30px;
}

.p32 {
  padding: 32px;
}

.pt32 {
  padding-top: 32px;
}

.pb32 {
  padding-bottom: 32px;
}

.pl32 {
  padding-left: 32px;
}

.pr32 {
  padding-right: 32px;
}

.p35 {
  padding: 35px;
}

.pt35 {
  padding-top: 35px;
}

.pb35 {
  padding-bottom: 35px;
}

.pl35 {
  padding-left: 35px;
}

.pr35 {
  padding-right: 35px;
}

.p36 {
  padding: 36px;
}

.pt36 {
  padding-top: 36px;
}

.pb36 {
  padding-bottom: 36px;
}

.pl36 {
  padding-left: 36px;
}

.pr36 {
  padding-right: 36px;
}

.p40 {
  padding: 40px;
}

.pt40 {
  padding-top: 40px;
}

.pb40 {
  padding-bottom: 40px;
}

.pl40 {
  padding-left: 40px;
}

.pr40 {
  padding-right: 40px;
}

.tip-body {
  line-height: 20px;
}
.tip-body ul, .tip-body ol {
  padding-left: 20px;
  margin: 4px 0 0 0;
}

.badge {
  display: inline-block;
  padding: 2px 6px;
  background-color: #C3C7CF;
  color: #2B2B2B;
  border-radius: 24px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.015em;
  margin-left: 4px;
}

.activity-status {
  padding: 6px 9px;
  border-radius: 4px;
  width: fit-content;
  font-size: 14px;
  line-height: 16px;
  cursor: default;
  white-space: nowrap;
}
.activity-status.active {
  color: #317DF5;
  background-color: #E8F2FF;
  border: solid 1px #317DF5;
}
.activity-status.inactive {
  color: #8E9AA4;
  border: solid 1px #8E9AA4;
}
.activity-status.deleted {
  color: #FA4242;
  border: solid 1px #FA4242;
}

.testing-status {
  padding: 6px 9px;
  border-radius: 4px;
  width: fit-content;
  font-size: 14px;
  line-height: 16px;
  cursor: default;
  white-space: nowrap;
  color: #C3C7CF;
  border: solid 1px #C3C7CF;
}
.testing-status.status-SUCCESS {
  color: #3EBD29;
  background-color: #C8F9C1;
  border: solid 1px #3EBD29;
}
.testing-status.status-FAIL, .testing-status.status-FAILED, .testing-status.status-ERROR {
  background-color: #FFD6D6;
  color: #FA4242;
  border: solid 1px #FA4242;
}
.testing-status.status-RUNNING, .testing-status.status-IN_PROGRESS {
  background-color: #E8F2FF;
  color: #317DF5;
  border: solid 1px #317DF5;
}

.online-status {
  text-align: center;
  vertical-align: middle;
}
.online-status .online-marker {
  display: block;
  margin: auto;
  width: 12px;
  height: 12px;
  background: url("/assets/icons/icon-status-offline.svg") no-repeat center;
}
.online-status .online-marker.online {
  background: url("/assets/icons/icon-status-online.svg") no-repeat center;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -4px;
  margin-top: -4px;
}
.tags-list .tag {
  display: block;
  margin-left: 4px;
  margin-top: 4px;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #353A3E;
  background-color: #E8F2FF;
  border-radius: 4px;
  word-break: break-word;
}
.tags-list .tag.selected {
  background-color: #FFD6D6;
}

.status-flag {
  width: 24px;
  height: 24px;
  display: inline-block;
}
.status-flag.attention {
  background: url("/assets/icons/icon-status-attention.svg") no-repeat center;
}
.status-flag.checked {
  background: url("/assets/icons/icon-status-checked.svg") no-repeat center;
}
.status-flag.blocked {
  background: url("/assets/icons/icon-status-blocked.svg") no-repeat center;
}
.status-flag.missing {
  background: url("/assets/icons/icon-status-missing.svg") no-repeat center;
}

.align-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.align-container > * {
  margin-left: 24px;
}
.align-container > *:first-child {
  margin-left: 0;
}
.align-container.small-spacing > * {
  margin-left: 8px;
}
.align-container.small-spacing > *:first-child {
  margin-left: 0;
}
.align-container.mid-spacing > * {
  margin-left: 16px;
}
.align-container.mid-spacing > *:first-child {
  margin-left: 0;
}
.align-container.space-between {
  justify-content: space-between;
  width: 100%;
}

.inner-view, .sub-view {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  padding-top: 40px;
}
.inner-view.inner-view, .sub-view.inner-view {
  background-color: #E5E5E5;
}
.inner-view.sub-view, .sub-view.sub-view {
  padding: 0;
  width: auto;
}
.inner-view .view-section, .inner-view .view-header, .sub-view .view-section, .sub-view .view-header {
  display: flex;
  min-height: 0;
  overflow: hidden;
}
.inner-view .view-section + .view-section, .inner-view .view-header + .view-section, .sub-view .view-section + .view-section, .sub-view .view-header + .view-section {
  margin-top: 24px;
}
.inner-view .view-section, .sub-view .view-section {
  flex-flow: column;
}
.inner-view .view-section.search-block, .inner-view .view-section.filtering-panel, .sub-view .view-section.search-block, .sub-view .view-section.filtering-panel {
  flex-flow: row;
}
.inner-view .view-header, .sub-view .view-header {
  flex-shrink: 0;
  flex-flow: row;
}
.inner-view .view-header.with-breadcrumbs, .sub-view .view-header.with-breadcrumbs {
  flex-flow: column;
}
.inner-view .panel, .sub-view .panel {
  background-color: #FFF;
  overflow: auto;
  min-height: 0;
  max-height: 100%;
  border-radius: 4px;
  border: solid 1px #C3C7CF;
}
.inner-view .panel .panel-header, .sub-view .panel .panel-header {
  border-bottom: 1px solid #C3C7CF;
}
.inner-view .panel .panel-header.no-separator, .sub-view .panel .panel-header.no-separator {
  border-bottom: none;
}
.inner-view .panel .panel-section, .sub-view .panel .panel-section {
  padding: 24px;
}
.inner-view .panel .panel-section + .panel-section, .sub-view .panel .panel-section + .panel-section {
  border-top: 1px solid #C3C7CF;
}
.inner-view .panel .panel-section + .panel-section.no-separator, .sub-view .panel .panel-section + .panel-section.no-separator {
  border-top: none;
}
.inner-view .pagination-block, .sub-view .pagination-block {
  flex-shrink: 0;
  padding: 16px 24px;
  background-color: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.08);
  z-index: 2;
  margin-top: 0;
}
.inner-view .pagination-block .text-info, .sub-view .pagination-block .text-info {
  font-weight: 700;
}
.inner-view .pagination-block.attached, .sub-view .pagination-block.attached {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #C3C7CF;
  margin-top: -1px;
}

.grayed-panel {
  border: 1px solid rgba(195, 199, 207, 0.4);
  background-color: #F5F6FA;
  padding: 16px;
  border-radius: 8px;
}

.results-block, .scroll-container {
  display: flex;
  flex-flow: column;
}
.results-block perfect-scrollbar, .scroll-container perfect-scrollbar {
  display: flex;
  flex-flow: column;
  height: auto;
  align-items: stretch;
}

.ps.ps--active-y .scroll-area {
  padding-right: 18px;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .results-block, .scroll-container perfect-scrollbar {
      height: unset;
    }
  }
}
.mat-mdc-tab-group {
  width: 100%;
  flex-grow: 1;
  min-height: 0;
}
.mat-mdc-tab-group .mat-mdc-tab-header {
  width: 100%;
  border: none;
  flex-shrink: 0;
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list {
  border-bottom: 1px solid #C3C7CF;
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab {
  flex-grow: 0;
  color: #317DF5;
  font-weight: 500;
  opacity: 1;
  padding: 15px 24px;
  height: unset;
  min-width: unset;
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab .mdc-tab__text-label {
  letter-spacing: normal;
  color: #317DF5;
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab--active {
  border: 1px solid #C3C7CF;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  background-color: #FFF;
  position: relative;
  top: 1px;
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab .mat-mdc-tab-ripple, .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab .mdc-tab-indicator {
  display: none;
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab:hover .mdc-tab__ripple:before {
  opacity: 0;
}
.mat-mdc-tab-group .mat-mdc-tab-header .mat-ink-bar {
  display: none;
}
.mat-mdc-tab-group .mat-mdc-tab-body-wrapper {
  flex-grow: 1;
  min-height: 0;
  background-color: #FFF;
  border-top: none;
}
.mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body-content {
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body-content > * {
  display: flex;
  flex-flow: column;
  min-height: 0;
}

.cdk-overlay-pane {
  max-width: 80%;
}
.cdk-overlay-pane.hidden-dialog {
  display: none;
}
.cdk-overlay-pane .mdc-dialog__container {
  background-color: #FFF;
  padding: 0;
  height: 100%;
  min-height: 0;
}
.cdk-overlay-pane .mdc-dialog__container .mat-mdc-dialog-surface {
  display: flex;
}
.cdk-overlay-pane .mdc-dialog__container .mat-mdc-dialog-surface > * {
  display: flex;
  height: 100%;
  min-height: 0;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  width: 100%;
  min-height: 0;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header, .cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-body, .cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-footer {
  width: 100%;
  display: flex;
  flex-flow: row;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #C3C7CF;
  padding: 16px 21px;
  flex-shrink: 0;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title, .cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title-container {
  flex-grow: 1;
  color: #353A3E;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title .icon, .cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title-container .icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 11px;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title .icon.warning, .cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title-container .icon.warning {
  background: url("/assets/icons/icon-dialog-warning.svg") no-repeat center;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title .icon.error, .cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title-container .icon.error {
  background: url("/assets/icons/icon-dialog-error.svg") no-repeat center;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title .icon.success, .cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .title-container .icon.success {
  background: url("/assets/icons/icon-dialog-success.svg") no-repeat center;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-header .close-icon {
  flex-shrink: 0;
  background: url("/assets/icons/icon-close.svg") no-repeat center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-body {
  flex-flow: column;
  flex-grow: 1;
  align-items: flex-start;
  color: #353A3E;
  padding: 24px;
  min-height: 0;
  height: 100%;
  overflow: hidden;
  word-break: break-word;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-footer {
  padding: 16px;
  align-items: center;
  flex-shrink: 0;
  border-top: solid 1px #C3C7CF;
  justify-content: space-between;
}
.cdk-overlay-pane .mdc-dialog__container .dialog-content .dialog-footer.align-right {
  justify-content: flex-end;
}
.cdk-overlay-pane .mat-mdc-snack-bar-container {
  padding: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  background-color: #FFF;
  border-radius: 4px;
}
.cdk-overlay-pane .mat-mdc-snack-bar-container .mdc-snackbar__surface {
  padding-right: 0;
}
.cdk-overlay-pane .mat-mdc-snack-bar-container .mdc-snackbar__surface .mat-mdc-snack-bar-label {
  padding: 0;
}

.popover {
  z-index: 1000;
  max-width: 350px;
}
.popover.modification-models-popover {
  max-width: 362px;
}
.popover.modification-models-popover .models-field {
  max-height: 245px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  min-height: 0;
  height: 100%;
}
.popover.bs-popover-bottom .popover-arrow {
  top: -24px;
  border-color: transparent;
  border-bottom-color: #C2F8BA;
  border-width: 12px 8px;
  border-style: solid;
  width: 16px;
  height: 24px;
}
.popover.bs-popover-top .popover-arrow {
  bottom: -24px;
  border-color: transparent;
  border-top-color: #C2F8BA;
  border-width: 12px 8px;
  border-style: solid;
  width: 16px;
  height: 24px;
}
.popover .popover-body {
  background-color: #C2F8BA;
  padding: 16px;
  border-radius: 8px;
}
.popover.warning-tip.bs-popover-bottom .popover-arrow {
  border-bottom-color: #FDEBAB;
}
.popover.warning-tip.bs-popover-top .popover-arrow {
  border-top-color: #FDEBAB;
}
.popover.warning-tip .popover-body {
  background-color: #FDEBAB;
}
.popover.error-tip.bs-popover-bottom .popover-arrow {
  border-bottom-color: #FA4242;
}
.popover.error-tip.bs-popover-top .popover-arrow {
  border-top-color: #FA4242;
}
.popover.error-tip .popover-body {
  background-color: #FA4242;
}
.popover.shift-left .popover-body {
  position: relative;
  left: 40px;
}

.loading-dialog .spinner-three-bounce {
  display: none;
}
.loading-dialog .loading-banner {
  position: fixed;
  width: 260px;
  height: 170px;
  top: calc(50% - 85px);
  left: calc(50% - 130px);
  z-index: 2000;
  padding: 32px;
  background-color: #FFF;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.loading-dialog .loading-banner .text {
  margin-top: 24px;
  text-align: center;
  color: #353A3E;
  line-height: 24px;
}

button, a.button, div.button {
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto", serif;
  padding: 11px 22px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  outline: none;
}
button.primary, a.button.primary, div.button.primary {
  background-color: #317DF5;
  color: #FFF;
  border: none;
}
button.secondary, a.button.secondary, div.button.secondary {
  background-color: #F8F9FA;
  color: #2B2B2B;
  border: 1px solid #C3C7CF;
}
button.button-delete, a.button.button-delete, div.button.button-delete {
  background-color: #F8F9FA;
  color: #FA4242;
  padding-right: 40px;
  background-image: url("/assets/icons/icon-delete-active.svg");
  background-repeat: no-repeat;
  background-position: right 8px top 8px;
  border: solid 1px #C3C7CF;
}
button.button-delete:hover, a.button.button-delete:hover, div.button.button-delete:hover {
  background-color: #FFD6D6;
  border: 1px solid #FA4242;
}
button.button-delete[disabled], button.button-delete.disabled, a.button.button-delete[disabled], a.button.button-delete.disabled, div.button.button-delete[disabled], div.button.button-delete.disabled {
  border: solid 1px #C3C7CF;
  background-image: url("/assets/icons/icon-delete-disabled.svg");
  background-color: #FFF;
  color: #C3C7CF;
}
button.button-delete[disabled]:hover, button.button-delete.disabled:hover, a.button.button-delete[disabled]:hover, a.button.button-delete.disabled:hover, div.button.button-delete[disabled]:hover, div.button.button-delete.disabled:hover {
  background-color: #FFF;
  border: solid 1px #C3C7CF;
}
button[disabled], button.disabled, a.button[disabled], a.button.disabled, div.button[disabled], div.button.disabled {
  background-color: #C3C7CF;
  color: #FFF;
  cursor: default;
}
button[disabled].secondary, button.disabled.secondary, a.button[disabled].secondary, a.button.disabled.secondary, div.button[disabled].secondary, div.button.disabled.secondary {
  background-color: #FFF;
  color: #C3C7CF;
}
button.wide, a.button.wide, div.button.wide {
  width: 100%;
}
button.button-add, a.button.button-add, div.button.button-add {
  padding-right: 40px;
  background-image: url("/assets/icons/icon-add.svg");
  background-repeat: no-repeat;
  background-position: right 8px top 8px;
}
button.button-remove, a.button.button-remove, div.button.button-remove {
  width: 24px;
  height: 24px;
  background: url("/assets/icons/icon-remove.svg") no-repeat center #F5F6FA;
}
button.button-remove-line, a.button.button-remove-line, div.button.button-remove-line {
  width: 24px;
  height: 24px;
  background: url("/assets/icons/icon-remove-line.svg") no-repeat center;
  padding: 0;
  border: none;
}
button.button-export-options, a.button.button-export-options, div.button.button-export-options {
  padding-right: 48px;
  padding-left: 48px;
  background-image: url("/assets/icons/icon-export.svg"), url("/assets/icons/icon-actions-arrow.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left 15px top 7px, right 15px top 7px;
  background-color: #F5F6FA;
  border: solid 1px #C3C7CF;
  color: #2B2B2B;
}
button.button-export, a.button.button-export, div.button.button-export {
  padding-left: 16px;
  padding-right: 40px;
  background-image: url("/assets/icons/icon-save.svg");
  background-repeat: no-repeat;
  background-position: right 8px top 8px;
}
button.button-arrow-fill, a.button.button-arrow-fill, div.button.button-arrow-fill {
  width: 40px;
  height: 40px;
  background-color: white;
  background-image: url("/assets/icons/icon-arrow-fill.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  border: solid 1px #C3C7CF;
}
button.button-arrow-fill:disabled, a.button.button-arrow-fill:disabled, div.button.button-arrow-fill:disabled {
  background-color: #F5F6FA;
  opacity: 0.7;
}
button.button-upload, a.button.button-upload, div.button.button-upload {
  padding-right: 48px;
  background-image: url("/assets/icons/icon-upload-grey.svg");
  background-repeat: no-repeat;
  background-position: right 15px top 8px;
}
button.button-download, a.button.button-download, div.button.button-download {
  width: 40px;
  height: 40px;
  background: url("/assets/icons/icon-download.svg") no-repeat center;
  padding: 0;
  border: 1px solid #C3C7CF;
}
button.button-prev, button.button-next, button.button-down, button.button-up, button.button-file-select, button.button-remove, button.button-remove-line, button.button-remove-line-active, button.button-view-details, a.button.button-prev, a.button.button-next, a.button.button-down, a.button.button-up, a.button.button-file-select, a.button.button-remove, a.button.button-remove-line, a.button.button-remove-line-active, a.button.button-view-details, div.button.button-prev, div.button.button-next, div.button.button-down, div.button.button-up, div.button.button-file-select, div.button.button-remove, div.button.button-remove-line, div.button.button-remove-line-active, div.button.button-view-details {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
}
button.button-remove, a.button.button-remove, div.button.button-remove {
  background: url("/assets/icons/icon-remove.svg") no-repeat center;
}
button.button-file-select, a.button.button-file-select, div.button.button-file-select {
  background: url("/assets/icons/icon-file-select.svg") no-repeat center;
}
button.button-remove-line, a.button.button-remove-line, div.button.button-remove-line {
  background: url("/assets/icons/icon-remove-line.svg") no-repeat center;
}
button.button-remove-line[disabled], button.button-remove-line.disabled, a.button.button-remove-line[disabled], a.button.button-remove-line.disabled, div.button.button-remove-line[disabled], div.button.button-remove-line.disabled {
  background: url("/assets/icons/icon-remove-line-disabled.svg") no-repeat center;
}
button.button-remove-line-active, a.button.button-remove-line-active, div.button.button-remove-line-active {
  background: url("/assets/icons/icon-remove-line-active.svg") no-repeat center;
}
button.button-remove-line-active[disabled], button.button-remove-line-active.disabled, a.button.button-remove-line-active[disabled], a.button.button-remove-line-active.disabled, div.button.button-remove-line-active[disabled], div.button.button-remove-line-active.disabled {
  background: url("/assets/icons/icon-remove-line-disabled.svg") no-repeat center;
}
button.button-view-details, a.button.button-view-details, div.button.button-view-details {
  background: url("/assets/icons/icon-view.svg") no-repeat center;
}
button.button-prev, a.button.button-prev, div.button.button-prev {
  background: url("/assets/icons/icon-prev.svg") no-repeat center;
}
button.button-prev[disabled], button.button-prev.disabled, a.button.button-prev[disabled], a.button.button-prev.disabled, div.button.button-prev[disabled], div.button.button-prev.disabled {
  background: url("/assets/icons/icon-prev-disabled.svg") no-repeat center;
}
button.button-next, a.button.button-next, div.button.button-next {
  background: url("/assets/icons/icon-next.svg") no-repeat center;
}
button.button-next[disabled], button.button-next.disabled, a.button.button-next[disabled], a.button.button-next.disabled, div.button.button-next[disabled], div.button.button-next.disabled {
  background: url("/assets/icons/icon-next-disabled.svg") no-repeat center;
}
button.button-up, a.button.button-up, div.button.button-up {
  background: url("/assets/icons/icon-up.svg") no-repeat center;
}
button.button-up[disabled], button.button-up.disabled, a.button.button-up[disabled], a.button.button-up.disabled, div.button.button-up[disabled], div.button.button-up.disabled {
  background: url("/assets/icons/icon-up-disabled.svg") no-repeat center;
}
button.button-down, a.button.button-down, div.button.button-down {
  background: url("/assets/icons/icon-up.svg") no-repeat center;
  transform: scaleY(-1);
}
button.button-down[disabled], button.button-down.disabled, a.button.button-down[disabled], a.button.button-down.disabled, div.button.button-down[disabled], div.button.button-down.disabled {
  background: url("/assets/icons/icon-up-disabled.svg") no-repeat center;
}
button.button-back, a.button.button-back, div.button.button-back {
  padding: 12px 24px 12px 46px;
  background-image: url("/assets/icons/icon-previous.svg");
  background-repeat: no-repeat;
  background-position: left 14px top 8px;
}
button.button-back[disabled], a.button.button-back[disabled], div.button.button-back[disabled] {
  background-image: url("/assets/icons/icon-previous-disabled.svg");
}
button.button-forward, a.button.button-forward, div.button.button-forward {
  padding: 12px 46px 12px 24px;
  background-image: url("/assets/icons/icon-forward.svg");
  background-repeat: no-repeat;
  background-position: right 14px top 8px;
}
button.button-view, a.button.button-view, div.button.button-view {
  width: 40px;
  height: 40px;
  background: url("/assets/icons/icon-view.svg") no-repeat center #F5F6FA;
  border: 1px solid #C3C7CF;
  padding: 0;
}
button.button-actions, a.button.button-actions, div.button.button-actions {
  display: inline-block;
  padding: 0;
  width: 40px;
  background: url("/assets/icons/icon-ellipsis-vertical.svg") no-repeat center #F5F6FA;
  border: 1px solid #C3C7CF;
  border-radius: 4px;
}
button.button-clear, a.button.button-clear, div.button.button-clear {
  width: 16px;
  height: 16px;
  background: url("/assets/icons/icon-clear.svg") no-repeat center;
  border: none;
  padding: 0;
}
button.validation-bubble, a.button.validation-bubble, div.button.validation-bubble {
  width: 20px;
  height: 20px;
  background: url("/assets/icons/icon-validation.svg") no-repeat center;
  border: none;
  padding: 0;
}
button.attention-bubble, a.button.attention-bubble, div.button.attention-bubble {
  width: 24px;
  height: 24px;
  background: url("/assets/icons/icon-attention.svg") no-repeat center;
  border: none;
  padding: 0;
}
button.information, a.button.information, div.button.information {
  display: inline-block;
  margin-left: 8px;
  width: 24px;
  height: 24px;
  background: url("/assets/icons/icon-information.svg") no-repeat center;
  border: none;
  padding: 0;
}
button + button, button + a.button, a.button + button, a.button + a.button, div.button + button, div.button + a.button {
  margin-left: 16px;
}
button.link-button, a.button.link-button, div.button.link-button {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  height: auto;
  background: none;
  border: none;
  padding: 0;
  font-weight: 400;
  text-decoration: underline;
  color: #317DF5;
}
button.link-button.bold-link, a.button.link-button.bold-link, div.button.link-button.bold-link {
  font-weight: 500;
  text-decoration: none;
}
button.link-button .link-icon, a.button.link-button .link-icon, div.button.link-button .link-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
button.link-button .link-icon.add-item, a.button.link-button .link-icon.add-item, div.button.link-button .link-icon.add-item {
  background: url("/assets/icons/icon-add-item.svg") no-repeat center;
}
button.link-button .link-icon.navigate, a.button.link-button .link-icon.navigate, div.button.link-button .link-icon.navigate {
  background: url("/assets/icons/icon-navigate.svg") no-repeat center;
}
button.link-button .link-icon.button-upload, a.button.link-button .link-icon.button-upload, div.button.link-button .link-icon.button-upload {
  background: url("/assets/icons/icon-upload-blue.svg") no-repeat center;
}
button.link-button:disabled, a.button.link-button:disabled, div.button.link-button:disabled {
  color: #C3C7CF;
}
button.link-button:disabled .link-icon.add-item, a.button.link-button:disabled .link-icon.add-item, div.button.link-button:disabled .link-icon.add-item {
  background: url("/assets/icons/icon-add-item-disabled.svg") no-repeat center;
}
button.remove-button, a.button.remove-button, div.button.remove-button {
  width: 24px;
  height: 24px;
  padding: 0;
  background: url("/assets/icons/icon-remove-button.svg") no-repeat center;
  border: none;
}

.input-container {
  position: relative;
}
.input-container .controls {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-right: 8px;
  height: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
}
.input-container .controls > * {
  margin-left: 4px;
}
.input-container .controls > *:first-child {
  margin-left: 0;
}
.input-container .controls .validation-bubble, .input-container .controls .button-clear {
  display: none;
}
.input-container.clearable .button-clear {
  display: block;
}
.input-container.clearable input {
  padding-right: 28px;
}
.input-container.invalid .validation-bubble {
  display: block;
}
.input-container.invalid input {
  padding-right: 32px;
  border: solid 1px #FA4242;
}
.input-container.clearable.invalid input {
  padding-right: 52px;
}

input[type=text], textarea {
  background: #FFF;
  border: 1px solid #C3C7CF;
  border-radius: 4px;
  color: #353A3E;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  outline: none;
  margin: 0;
}
input[type=text]::placeholder, textarea::placeholder {
  color: #C3C7CF;
  text-transform: none;
}
input[type=text].invalid, textarea.invalid {
  border: solid 1px #FA4242;
}
input[type=text]:focus, textarea:focus {
  border: solid 1px #317DF5;
}
input[type=text][disabled], input[type=text].disabled, textarea[disabled], textarea.disabled {
  background-color: #F5F6FA;
  color: #8E9AA4;
}
input[type=text].clearable, textarea.clearable {
  padding-right: 40px;
}

input[type=text], textarea {
  padding: 11px 12px;
}
input[type=text].narrow-input, textarea.narrow-input {
  padding: 7px 8px;
}
input[type=text].narrow-input.clearable, textarea.narrow-input.clearable {
  padding-right: 28px;
}

textarea {
  width: 100%;
  height: 64px;
  padding: 8px;
}

.dropdown-panel {
  width: 100%;
  display: block;
  border: 1px solid #C3C7CF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background-color: #FFF;
}
.dropdown-panel.limited-height {
  max-height: 250px;
}
.dropdown-panel .dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  color: #353A3E;
  cursor: pointer;
  border-top: 1px solid #C3C7CF;
  font-size: 14px;
  line-height: 16px;
}
.dropdown-panel .dropdown-item .item-text {
  flex-grow: 1;
  word-break: break-word;
}
.dropdown-panel .dropdown-item .additional {
  color: #8E9AA4;
}
.dropdown-panel .dropdown-item:first-child {
  border-top: none;
}
.dropdown-panel .dropdown-item.selected {
  background-color: rgba(49, 125, 245, 0.2);
  cursor: default;
}
.dropdown-panel .dropdown-item:hover {
  background-color: #E8F2FF;
}
.dropdown-panel .dropdown-item.disabled {
  color: #8E9AA4;
  cursor: default;
}
.dropdown-panel .dropdown-item.disabled:hover {
  background-color: #FFF;
}
.dropdown-panel.items-panel {
  padding: 16px;
  max-height: unset;
}
.dropdown-panel.items-panel .year-selection-panel {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #C3C7CF;
  margin-bottom: 16px;
}
.dropdown-panel.items-panel .items-container {
  display: grid;
  grid-gap: 8px 16px;
  gap: 8px 16px;
}
.dropdown-panel.items-panel .items-container .selectable-item {
  color: #536371;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}
.dropdown-panel.items-panel .items-container .selectable-item:hover {
  background-color: #E8F2FF;
}
.dropdown-panel.items-panel .items-container .selectable-item.disabled {
  color: #C3C7CF;
  cursor: default;
}
.dropdown-panel.items-panel .items-container .selectable-item.disabled:hover {
  background-color: #FFF;
}
.dropdown-panel.items-panel .items-container .selectable-item.selected {
  background-color: #317DF5;
  color: #FFF;
  cursor: default;
}
.dropdown-panel.items-panel .items-container .selectable-item.selected:hover {
  background-color: #317DF5;
}
.dropdown-panel.items-panel .years-container {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.dropdown-panel.items-panel .months-container {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.dropdown-panel.items-panel .months-container .month-item {
  width: 128px;
}
.dropdown-panel .tree-container {
  display: block;
  width: 100%;
}
.dropdown-panel .tree-container.monospace {
  font-family: "Source Code Pro", monospace;
}
.dropdown-panel .tree-container.monospace .label.node-name {
  font-family: "Source Code Pro", monospace;
}
.dropdown-panel .tree-container .children-container {
  padding-left: 16px;
  overflow: hidden;
  transition: max-height 0.8s;
}
.dropdown-panel .tree-container .children-container.collapsed {
  max-height: 0;
}
.dropdown-panel .tree-container .children-container.no-padding {
  padding-left: 0;
}
.dropdown-panel .tree-container .node-row {
  padding: 4px 8px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
}
.dropdown-panel .tree-container .node-row.hidden {
  display: none;
}
.dropdown-panel .tree-container .node-icon {
  width: 24px;
  height: 24px;
}
.dropdown-panel .tree-container .node-icon.expand-collapse {
  background: url("/assets/icons/icon-tree-expand-collapse.svg") no-repeat center;
  transition: transform 0.5s;
}
.dropdown-panel .tree-container .node-icon.expand-collapse.collapsed {
  transform: rotate(-90deg);
}
.dropdown-panel .tree-container .node-name.label {
  font-weight: normal;
}

.checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 8px;
  background: url("/assets/icons/icon-checkbox.svg") no-repeat center #FFF;
  flex-shrink: 0;
}
.checkbox:hover {
  background: url("/assets/icons/icon-checkbox-hover.svg") no-repeat center #FFF;
}
.checkbox.checked {
  background: url("/assets/icons/icon-checkbox-checked.svg") no-repeat center #FFF;
}
.checkbox.checked:hover {
  background: url("/assets/icons/icon-checkbox-checked-hover.svg") no-repeat center #FFF;
}
.checkbox.half-checked {
  background: url("/assets/icons/icon-checkbox-halfchecked.svg") no-repeat center #FFF;
}
.checkbox.half-checked:hover {
  background: url("/assets/icons/icon-checkbox-halfchecked-hover.svg") no-repeat center #FFF;
}
.checkbox.disabled {
  background: url("/assets/icons/icon-checkbox-disabled.svg") no-repeat center #FFF;
  cursor: default;
}
.checkbox.disabled:hover {
  background: url("/assets/icons/icon-checkbox-disabled.svg") no-repeat center #FFF;
}
.checkbox.disabled.checked {
  background: url("/assets/icons/icon-checkbox-checked-disabled.svg") no-repeat center #FFF;
}
.checkbox.disabled.checked:hover {
  background: url("/assets/icons/icon-checkbox-checked-disabled.svg") no-repeat center #FFF;
}
.checkbox.disabled.half-checked {
  background: url("/assets/icons/icon-checkbox-halfchecked-disabled.svg") no-repeat center #FFF;
}
.checkbox.disabled.half-checked:hover {
  background: url("/assets/icons/icon-checkbox-halfchecked-disabled.svg") no-repeat center #FFF;
}

.mat-calendar .mat-calendar-content .mat-calendar-table-header th {
  padding-bottom: 12px;
}
.mat-calendar .mat-calendar-content .mat-calendar-table-header .mat-calendar-table-header-divider::after {
  left: 8px;
  right: 8px;
  background-color: #C3C7CF;
}

form .field {
  margin-bottom: 24px;
}
form .field-name {
  margin-bottom: 8px;
}

.loader {
  width: 32px;
  height: 32px;
  background: url("/assets/icons/loading.svg") no-repeat center;
  animation: spin 1s infinite linear;
}
.loader.centered {
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  from {
    transform: rotate(-359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.inner-view table.results-table thead th {
  padding: 28px 24px;
}

table.results-table {
  width: 100%;
  border-collapse: collapse;
  background: #FFF;
  word-break: normal;
}
table.results-table tr {
  border-top: 1px solid rgba(195, 199, 207, 0.4);
}
table.results-table tr:first-child {
  border: none;
}
table.results-table tr.totals-row {
  border: none;
}
table.results-table tr.totals-row th, table.results-table tr.totals-row td {
  background-color: #F5F6FA;
  padding: 12px 24px;
}
table.results-table tr th, table.results-table tr td {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  padding: 16px 24px;
}
table.results-table tr th.centered, table.results-table tr td.centered {
  text-align: center;
}
table.results-table tr th.align-right, table.results-table tr td.align-right {
  text-align: right;
}
table.results-table tr th.thin-space, table.results-table tr td.thin-space {
  padding: 10px 24px;
}
table.results-table tr th.button-column, table.results-table tr td.button-column {
  line-height: 0;
}
table.results-table tr th {
  position: sticky;
  top: 0;
  color: #353A3E;
  background: #FFF;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: inset 0 -1px 0 #C2C2C2;
}
table.results-table tr th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  border-top: 1px solid #C2C2C2;
}
table.results-table tr td {
  font-weight: 400;
  color: #536371;
  vertical-align: middle;
}
table.results-table tr td.by-content {
  width: 1%;
  white-space: nowrap;
}
table.results-table tr td.table-check {
  background-image: url("/assets/icons/icon-table-check-disabled.svg");
  background-repeat: no-repeat;
  background-position: 24px center;
}
table.results-table tr td.table-check.checked {
  background-image: url("/assets/icons/icon-table-check.svg");
}
table.results-table tr td.table-check.centered {
  background-position: center;
}
table.results-table tr td a {
  color: #000;
}
table.results-table.static-borders {
  border: solid 1px #C3C7CF;
}
table.results-table.static-borders tr {
  border-top: 1px solid #C3C7CF;
}
table.results-table.static-borders tr:first-child {
  border-top: 1px solid #C3C7CF;
}
table.results-table.static-borders tr th {
  box-shadow: none;
}
table.results-table.static-borders tr th:before {
  content: none;
}
table.results-table.stripes-table {
  border-style: solid;
  border-color: #C3C7CF;
  border-width: 0 1px 1px 1px;
}
table.results-table.stripes-table tr {
  border-top: none;
}
table.results-table.stripes-table tr:nth-child(odd) {
  background-color: #F5F6FA;
}

table.grayed-titles {
  border-spacing: 24px 8px;
  margin: -8px -24px;
}
table.grayed-titles th, table.grayed-titles td {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  text-align: left;
}
table.grayed-titles th {
  color: #8E9AA4;
}
table.grayed-titles td {
  color: #2B2B2B;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: #353A3E;
  height: 100%;
  background-color: #E5E5E5;
  margin: 0;
  padding: 0;
}

* {
  font-family: Roboto, serif;
}

*, *:after, *:before {
  box-sizing: border-box;
}