button, a.button, div.button {
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Roboto', serif;
  padding: 11px 22px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  outline: none;
  &.primary {
    background-color: $button-primary;
    color: $white;
    border: none;
  }
  &.secondary {
    background-color: $background-component;
    color: $text-black;
    border: 1px solid $button-disabled;
  }
  &.button-delete {
    background-color: $background-component;
    color: $warning-text;
    padding-right: 40px;
    background-image: url("/assets/icons/icon-delete-active.svg");
    background-repeat: no-repeat;
    background-position: right 8px top 8px;
    border: solid 1px $button-disabled;
    &:hover {
      background-color: $error-background;
      border: 1px solid $error-highlight;
    }
    &[disabled], &.disabled {
      border: solid 1px $button-disabled;
      background-image: url("/assets/icons/icon-delete-disabled.svg");
      background-color: $white;
      color: $button-disabled;
      &:hover {
        background-color: $white;
        border: solid 1px $button-disabled;
      }
    }
  }
  &[disabled], &.disabled {
    background-color: $text-secondary;
    color: $white;
    cursor: default;
    &.secondary {
      background-color: $white;
      color: $text-secondary;
    }
  }
  &.wide {
    width: 100%;
  }
  &.button-add {
    padding-right: 40px;
    background-image: url("/assets/icons/icon-add.svg");
    background-repeat: no-repeat;
    background-position: right 8px top 8px;
  }
  &.button-remove {
    width: 24px;
    height: 24px;
    background: url("/assets/icons/icon-remove.svg") no-repeat center $component-disabled;
  }
  &.button-remove-line {
    width: 24px;
    height: 24px;
    background: url("/assets/icons/icon-remove-line.svg") no-repeat center;
    padding: 0;
    border: none;
  }
  &.button-export-options {
    padding-right: 48px;
    padding-left: 48px;
    background-image: url("/assets/icons/icon-export.svg"), url("/assets/icons/icon-actions-arrow.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: left 15px top 7px, right 15px top 7px;
    background-color: $component-disabled;
    border: solid 1px $border-light2;
    color: $text-black;
  }
  &.button-export {
    padding-left: 16px;
    padding-right: 40px;
    background-image: url("/assets/icons/icon-save.svg");
    background-repeat: no-repeat;
    background-position: right 8px top 8px;
  }
  &.button-arrow-fill {
    width: 40px;
    height: 40px;
    background-color: white;
    background-image: url("/assets/icons/icon-arrow-fill.svg");
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    border: solid 1px $border-light2;
    &:disabled {
      background-color: $component-disabled;
      opacity: 0.7;
    }
  }
  &.button-upload {
    padding-right: 48px;
    background-image: url("/assets/icons/icon-upload-grey.svg");
    background-repeat: no-repeat;
    background-position: right 15px top 8px;
  }
  &.button-download {
    width: 40px;
    height: 40px;
    background: url("/assets/icons/icon-download.svg") no-repeat center;
    padding: 0;
    border: 1px solid $button-disabled;
  }
  &.button-prev, &.button-next, &.button-down, &.button-up, &.button-file-select,
      &.button-remove, &.button-remove-line, &.button-remove-line-active, &.button-view-details {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
  }
  &.button-remove {
    background: url("/assets/icons/icon-remove.svg") no-repeat center;
  }
  &.button-file-select {
    background: url("/assets/icons/icon-file-select.svg") no-repeat center;
  }
  &.button-remove-line {
    background: url("/assets/icons/icon-remove-line.svg") no-repeat center;
    &[disabled], &.disabled {
      background: url("/assets/icons/icon-remove-line-disabled.svg") no-repeat center;
    }
  }
  &.button-remove-line-active {
    background: url("/assets/icons/icon-remove-line-active.svg") no-repeat center;
    &[disabled], &.disabled {
      background: url("/assets/icons/icon-remove-line-disabled.svg") no-repeat center;
    }
  }
  &.button-view-details {
    background: url("/assets/icons/icon-view.svg") no-repeat center;
  }
  &.button-prev {
    background: url("/assets/icons/icon-prev.svg") no-repeat center;
    &[disabled], &.disabled {
      background: url("/assets/icons/icon-prev-disabled.svg") no-repeat center;
    }
  }
  &.button-next {
    background: url("/assets/icons/icon-next.svg") no-repeat center;
    &[disabled], &.disabled {
      background: url("/assets/icons/icon-next-disabled.svg") no-repeat center;
    }
  }
  &.button-up,  {
    background: url("/assets/icons/icon-up.svg") no-repeat center;
    &[disabled], &.disabled {
      background: url("/assets/icons/icon-up-disabled.svg") no-repeat center;
    }
  }
  &.button-down,  {
    background: url("/assets/icons/icon-up.svg") no-repeat center;
    transform: scaleY(-1);
    &[disabled], &.disabled {
      background: url("/assets/icons/icon-up-disabled.svg") no-repeat center;
    }
  }
  &.button-back {
    padding: 12px 24px 12px 46px;
    background-image: url("/assets/icons/icon-previous.svg");
    background-repeat: no-repeat;
    background-position: left 14px top 8px;
    &[disabled] {
      background-image: url("/assets/icons/icon-previous-disabled.svg");
    }
  }
  &.button-forward {
    padding: 12px 46px 12px 24px;
    background-image: url("/assets/icons/icon-forward.svg");
    background-repeat: no-repeat;
    background-position: right 14px top 8px;
  }
  &.button-view {
    width: 40px;
    height: 40px;
    background: url("/assets/icons/icon-view.svg") no-repeat center $component-disabled;
    border: 1px solid $border-light2;
    padding: 0;
  }
  &.button-actions {
    display: inline-block;
    padding: 0;
    width: 40px;
    background: url("/assets/icons/icon-ellipsis-vertical.svg") no-repeat center $component-disabled;
    border: 1px solid $button-disabled;
    border-radius: 4px;
  }
  &.button-clear {
    width: 16px;
    height: 16px;
    background: url("/assets/icons/icon-clear.svg") no-repeat center;
    border: none;
    padding: 0;
  }
  &.validation-bubble {
    width: 20px;
    height: 20px;
    background: url("/assets/icons/icon-validation.svg") no-repeat center;
    border: none;
    padding: 0;
  }
  &.attention-bubble {
    width: 24px;
    height: 24px;
    background: url("/assets/icons/icon-attention.svg") no-repeat center;
    border: none;
    padding: 0;
  }
  &.information {
    display: inline-block;
    margin-left: 8px;
    width: 24px;
    height: 24px;
    background: url("/assets/icons/icon-information.svg") no-repeat center;
    border: none;
    padding: 0;
  }
  &+button, &+a.button {
    margin-left: 16px;
  }
  &.link-button {
    display: inline-flex;
    align-items: center;
    width: fit-content;
    height: auto;
    background: none;
    border: none;
    padding: 0;
    font-weight: 400;
    text-decoration: underline;
    color: $button-primary;
    &.bold-link {
      font-weight: 500;
      text-decoration: none;
    }
    .link-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      &.add-item {
        background: url("/assets/icons/icon-add-item.svg") no-repeat center;
      }
      &.navigate {
        background: url("/assets/icons/icon-navigate.svg") no-repeat center;
      }
      &.button-upload {
        background: url("/assets/icons/icon-upload-blue.svg") no-repeat center;
      }
    }
    &:disabled {
      .link-icon {
        &.add-item {
          background: url("/assets/icons/icon-add-item-disabled.svg") no-repeat center;
        }
      }
      color: $button-disabled;
    }
  }
  &.remove-button {
    width: 24px;
    height: 24px;
    padding: 0;
    background: url("/assets/icons/icon-remove-button.svg") no-repeat center;
    border: none;
  }
}

.input-container {
  position: relative;
  .controls {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-right: 8px;
    height: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    align-items: center;
    >* {
      margin-left: 4px;
      &:first-child {
        margin-left: 0;
      }
    }
    .validation-bubble, .button-clear {
      display: none;
    }
  }
  &.clearable {
    .button-clear {
      display: block;
    }
    input {
      padding-right: 28px;
    }
  }
  &.invalid {
    .validation-bubble {
      display: block;
    }
    input {
      padding-right: 32px;
      border: solid 1px $error-highlight;
    }
  }
  &.clearable.invalid input {
    padding-right: 52px;
  }
}
