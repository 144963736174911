@import "./_variables.scss";

.align-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  &>* {
    margin-left: 24px;
    &:first-child {
      margin-left: 0;
    }
  }
  &.small-spacing {
    &>* {
      margin-left: 8px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &.mid-spacing {
    &>* {
      margin-left: 16px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &.space-between {
    justify-content: space-between;
    width: 100%;
  }
}

.inner-view, .sub-view {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  padding-top: 40px;
  &.inner-view {
    background-color: $background;
  }
  &.sub-view {
    padding: 0;
    width: auto;
  }
  .view-section, .view-header {
    display: flex;
    min-height: 0;
    overflow: hidden;
    &+.view-section {
      margin-top: 24px;
    }
  }
  .view-section {
    flex-flow: column;
    &.search-block, &.filtering-panel {
      flex-flow: row;
    }
  }
  .view-header {
    flex-shrink: 0;
    flex-flow: row;
    &.with-breadcrumbs {
      flex-flow: column;
    }
  }

  .panel {
    background-color: $white;
    overflow: auto;
    min-height: 0;
    max-height: 100%;
    border-radius: 4px;
    border: solid 1px $border-light2;
    .panel-header {
      border-bottom: 1px solid $border-light2;
      &.no-separator {
        border-bottom: none;
      }
    }
    .panel-section {
      padding: 24px;
      &+.panel-section {
        border-top: 1px solid $border-light2;
        &.no-separator {
          border-top: none;
        }
      }
    }
  }

  .pagination-block {
    flex-shrink: 0;
    padding: 16px 24px;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.08);
    z-index: 2;
    margin-top: 0;
    .text-info {
      font-weight: 700;
    }
    &.attached {
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: $border-light2;
      margin-top: -1px;
    }
  }
}

.grayed-panel {
  border: 1px solid $border-light4;
  background-color: $component-disabled;
  padding: 16px;
  border-radius: 8px;
}

.results-block, .scroll-container {
  display: flex;
  flex-flow: column;
  perfect-scrollbar {
    display: flex;
    flex-flow: column;
    height: auto;
    align-items: stretch;
  }
}
.ps.ps--active-y .scroll-area {
  padding-right: 18px;
}
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .results-block, .scroll-container perfect-scrollbar {
      height: unset;
    }
  }
}

.mat-mdc-tab-group {
  width: 100%;
  flex-grow: 1;
  min-height: 0;
  .mat-mdc-tab-header {
    width: 100%;
    border: none;
    flex-shrink: 0;
    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        border-bottom: 1px solid $border-light2;
        .mat-mdc-tab {
          flex-grow: 0;
          color: $button-primary;
          font-weight: 500;
          opacity: 1;
          padding: 15px 24px;
          height: unset;
          min-width: unset;
          .mdc-tab__text-label {
            letter-spacing: normal;
            color: $border-highlight;
          }
          &.mdc-tab--active {
            border: 1px solid $border-light2;
            border-radius: 4px 4px 0 0;
            border-bottom: none;
            background-color: $white;
            position: relative;
            top: 1px;
          }
          .mat-mdc-tab-ripple, .mdc-tab-indicator {
            display: none;
          }
          &:hover .mdc-tab__ripple:before {
            opacity: 0;
          }
        }
      }
    }
    .mat-ink-bar {
      display: none;
    }
  }
  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
    min-height: 0;
    background-color: $white;
    border-top: none;
    .mat-mdc-tab-body-content {
      display: flex;
      flex-flow: column;
      overflow: hidden;
      &>* {
        display: flex;
        flex-flow: column;
        min-height: 0;
      }
    }
  }
}
