@import "./_variables.scss";

.view-title, h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-block-start: 0;
  margin-block-end: 0;
  color: $text-base;
}

.panel-header, h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: $text-base;
  margin: 0;
}
div.panel-header {
  padding: 24px;
}

.text-black {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $black;
}

.label {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $text-black;
}
.label-base {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $text-base;
}

.capitalize {
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }
}

.upper-case {
  text-transform: uppercase;
}

.highlighted {
  color: $border-highlight;
}

.centered {
  text-align: center;
  vertical-align: center;
}

.inline-block {
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-break: break-word;
}
.break-all {
  word-break: break-all;
}

hr {
  border: none;
  color: $border-light4;
  background-color: $border-light4;
  height: 1px;
  margin: 16px 0;
}

.max-attention {
  font-weight: 700;
  color: $warning-text;
  text-transform: uppercase;
}

.comma-separated {
  &>*:after {
    content: ', ';
  }
  &>*:last-child:after {
    content: none;
  }
  &.separate-lines {
    >* {
      display: block;
    }
  }
}

p {
  display: inline;
  padding-left: 16px;
}
.top-space {
  margin-top: 24px;
}
.less-space {
  margin-top: 16px;
}
.min-space {
  margin-top: 8px;
}
.info-list {
  // list-style: inside decimal;
  padding-left: 16px;
  &.two-digits {
    padding-left: 22px;
  }
  &>li {
    padding-top: 20px;
    &:first-child {
      padding-top: 0;
    }
  }
}

.text-grayed {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $text-disabled;
}

.validation {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.015em;
  color: $error-highlight;
  .validation-icon {
    width: 24px;
    height: 24px;
    background: url("/assets/icons/icon-message-error.svg") no-repeat center;
    margin-right: 8px;
  }
  .message+.message {
    display: none;
  }
}
.red {
  color: $error-highlight;
}

.tip-title, .tip-body {
  color: $text-black;
  font-size: 14px;
  line-height: 16px;
}
.tip-title, .tip-info-title {
  font-weight: 700;
}

$paddings: (0 4 5 6 8 10 12 14 15 16 20 24 25 30 32 35 36 40);

@each $padding in $paddings {
  .p#{$padding} {
    padding: #{$padding}px;
  }
  .pt#{$padding} {
    padding-top: #{$padding}px;
  }
  .pb#{$padding} {
    padding-bottom: #{$padding}px;
  }
  .pl#{$padding} {
    padding-left: #{$padding}px;
  }
  .pr#{$padding} {
    padding-right: #{$padding}px;
  }
}

.tip-body {
  line-height: 20px;
  ul, ol {
    padding-left: 20px;
    margin: 4px 0 0 0;
  }
}

.badge {
  display: inline-block;
  padding: 2px 6px;
  background-color: $text-secondary;
  color: $text-black;
  border-radius: 24px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.015em;
  margin-left: 4px;
}

.activity-status {
  padding: 6px 9px;
  border-radius: 4px;
  width: fit-content;
  font-size: 14px;
  line-height: 16px;
  cursor: default;
  white-space: nowrap;
  &.active {
    color: $button-primary;
    background-color: $active-highlight;
    border: solid 1px $button-primary;
  }
  &.inactive {
    color: $text-disabled;
    border: solid 1px $text-disabled;
  }
  &.deleted {
    color: $error-highlight;
    border: solid 1px $error-highlight;
  }
}

.testing-status {
  padding: 6px 9px;
  border-radius: 4px;
  width: fit-content;
  font-size: 14px;
  line-height: 16px;
  cursor: default;
  white-space: nowrap;
  color: $text-secondary;
  border: solid 1px $border-light2;
  &.status-SUCCESS {
    color: $success-text;
    background-color: $success-background;
    border: solid 1px $success-text;
  }
  &.status-FAIL, &.status-FAILED, &.status-ERROR {
    background-color: $error-background;
    color: $error-highlight;
    border: solid 1px $error-highlight;
  }
  &.status-RUNNING, &.status-IN_PROGRESS {
    background-color: $active-highlight;
    color: $border-highlight;
    border: solid 1px $border-highlight;
  }
}

.online-status {
  text-align: center;
  vertical-align: middle;
  .online-marker {
    display: block;
    margin: auto;
    width: 12px;
    height: 12px;
    background: url("/assets/icons/icon-status-offline.svg") no-repeat center;
    &.online {
      background: url("/assets/icons/icon-status-online.svg") no-repeat center;
    }
  }
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -4px;
  margin-top: -4px;
  .tag {
    display: block;
    margin-left: 4px;
    margin-top: 4px;
    padding: 4px 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $text-base;
    background-color: $active-highlight;
    border-radius: 4px;
    word-break: break-word;
    &.selected {
      background-color: $error-background;
    }
  }
}

.status-flag {
  width: 24px;
  height: 24px;
  display: inline-block;
  &.attention {
    background: url("/assets/icons/icon-status-attention.svg") no-repeat center;
  }
  &.checked {
    background: url("/assets/icons/icon-status-checked.svg") no-repeat center;
  }
  &.blocked {
    background: url("/assets/icons/icon-status-blocked.svg") no-repeat center;
  }
  &.missing {
    background: url("/assets/icons/icon-status-missing.svg") no-repeat center;
  }
}
