@use '@angular/material' as mat;
@include mat.core();
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "_fonts";
@import "_variables";
@import "_typography";
@import "_layout";
@import "_dialogs";
@import "_buttons";
@import "_forms";
@import "_tables";

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: $text-base;
  height: 100%;
  background-color: $background;
  margin: 0;
  padding: 0;
}

* {
  font-family: Roboto, serif;
}

*, *:after, *:before {
  box-sizing: border-box;
}


