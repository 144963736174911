$text-base: #353A3E;
$text-secondary: #C3C7CF;
$text-disabled: #8E9AA4;
$text-black: #2B2B2B;
$text-blue: #347BF6;
$text-grayed: #7C7C7C;
$status-text: #202125;
$table-text: #536371;

$white: #FFF;
$black: #000;

$background: #E5E5E5;
$logo-background: #E7E7E7;
$background-component: #F8F9FA;
$background-status: #ECECEC;
$component-disabled: #F5F6FA;
$selected-component: rgba(49, 125, 245, 0.2);
$sidebar-background: #353A3E;
$button-primary: #317DF5;
$button-disabled: #C3C7CF;
$active-menu: rgba(142, 154, 164, 0.2);
$active-highlight: #E8F2FF;
$border-light: #C2C2C2;
$border-light2: #C3C7CF;
$border-light3: #DFE2E6;
$border-light4: rgba(195, 199, 207, 0.4);
$border-light5: #D0D0D0;
$border-highlight: #317DF5;
$border-block: #405567;
$component-highlight: rgba(49, 125, 245, 0.2);
$table-row: #D8D8D8;
$success-highlight: #6DD25C;
$success-background: #C8F9C1;
$success-text: #3EBD29;
$warning-text: #FA4242;
$error-background: #FFD6D6;
$error-highlight: #FA4242;
$tip-color-ok: #C2F8BA;
$tip-color-warning: #FDEBAB;
